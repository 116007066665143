import Vue from "vue";
import { ValidationObserver } from "vee-validate";

/**
 * @description
 * Should only be used in components that are being used as router views.
 * Because `beforeRouteLeave` hook is only available in components that are defined
 * inside of routes and is not available for any subcomponents it may implement.
 */
export function useConfirmBackDialog() {
  return Vue.extend({
    extends: ValidationObserver,
    provide() {
      return { setDirtyFlag: this.setDirtyFlag };
    },
    methods: {
      setDirtyFlag(value) {
        this.flags.dirty = value;
      },
    },
    async beforeRouteLeave(to, from, next) {
      if (!this.flags.dirty) {
        return next();
      }

      const { title, message, okText, cancelText } = {
        ...this.$t("global.confirmBackDialog"),
        ...(this.$route.meta.confirmBackDialog || {}),
      };

      const confirm = await this.$dialog.confirm({
        title,
        message,
        okText,
        cancelText,
      });

      if (!confirm) {
        return next(false);
      }

      next();
    },
  });
}

/**
 * @description
 * Should only be used in child components of view component that implements `useConfirmBackDialog` mixin to
 * inject setter function for setting view component state that decides if confirm back dialog should be shown
 * or not.
 */
export function useConfirmBackDialogInject() {
  return Vue.extend({
    inject: {
      setDirtyFlag: {
        default: () => {},
      },
    },
  });
}
