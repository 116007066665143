<template>
  <VxDialog
    :title="$t('goal.setup.editDailyGoalDialog.title')"
    no-back
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="text-h6 primary--text text-uppercase mb-6">
      {{ formattedDate }}
    </div>
    <VxForm ref="form" v-slot="{ dirty }" class="mb-4" @submit="ok">
      <VxTable :headers="headers">
        <template #body>
          <tbody>
            <VxTableRow>
              <VxTableDataCell type="text">
                {{ $t("goal.setup.editDailyGoalDialog.headers.goal") }}
              </VxTableDataCell>
              <td class="pa-2">
                <VxTextField
                  v-model.number="form.salesGoal"
                  type="number"
                  rules="required|min_value:0"
                  :placeholder="
                    $t('goal.setup.editDailyGoalDialog.salesField.placeholder')
                  "
                  name="salesGoal"
                  prefix="$"
                  :min="0"
                  hide-details
                  dense
                />
              </td>
              <td class="pa-2">
                <VxTextField
                  v-model.number="form.avgTransGoal"
                  type="number"
                  rules="required|min_value:1"
                  :placeholder="
                    $t(
                      'goal.setup.editDailyGoalDialog.avgTransField.placeholder'
                    )
                  "
                  name="avgTransGoal"
                  prefix="$"
                  :min="1"
                  hide-details
                  dense
                />
              </td>
              <VxTableDataCell type="number">
                {{ customerCount }}
              </VxTableDataCell>
            </VxTableRow>
            <VxTableRow
              v-for="item in salesHistory"
              :key="item.year"
              class="text--secondary"
            >
              <VxTableDataCell type="text">{{ item.year }}</VxTableDataCell>
              <VxTableDataCell type="currency" :decimals="0">
                {{ item.sales }}
              </VxTableDataCell>
              <VxTableDataCell type="currency">
                {{ item.avgTrans }}
              </VxTableDataCell>
              <VxTableDataCell type="number">
                {{ item.customerCount }}
              </VxTableDataCell>
            </VxTableRow>
          </tbody>
        </template>
      </VxTable>
      <p v-show="dirty" class="text-caption py-2">
        {{ currentGoalsHint }}
      </p>
    </VxForm>
    <p
      v-for="(desc, key) in $t('goal.setup.editDailyGoalDialog.description')"
      :key="key"
    >
      {{ desc }}
    </p>
    <template #actions>
      <VxBtn secondary @click="$emit('cancel')">
        {{ $t("goal.setup.editDailyGoalDialog.cancelBtnText") }}
      </VxBtn>
      <VxBtn text @click="onSubmit">
        {{ $t("goal.setup.editDailyGoalDialog.okBtnText") }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import {
  VxDialog,
  VxTable,
  VxTableRow,
  VxTableDataCell,
  VxForm,
  VxTextField,
  VxBtn,
  formatDate,
  formatNumber,
} from "@/core-ui";

export default {
  name: "EditDailyGoalDialog",
  components: {
    VxDialog,
    VxTable,
    VxTableRow,
    VxTableDataCell,
    VxForm,
    VxTextField,
    VxBtn,
  },
  mixins: [
    useFormObject({
      prop: "dailyGoal",
      default: {
        businessDate: null,
        compDate: null,
        compDateTwoYearsAgo: null,
        salesGoal: null,
        avgTransGoal: null,
      },
    }),
  ],
  props: {
    monthGoals: {
      type: Array,
      default: () => [],
    },
  },
  data: (vm) => ({
    headers: [
      { title: "", field: "year", type: "text" },
      {
        title: vm.$t("goal.setup.editDailyGoalDialog.headers.sales"),
        field: "salesGoal",
        type: "currency",
      },
      {
        title: vm.$t("goal.setup.editDailyGoalDialog.headers.avgTrans"),
        field: "avgTransGoal",
        type: "currency",
      },
      {
        title: vm.$t("goal.setup.editDailyGoalDialog.headers.customerCount"),
        field: "customerCount",
        type: "number",
      },
    ],
  }),
  computed: {
    salesHistory() {
      const goal = this.monthGoals.find(
        (x) => x.businessDateOriginal === this.dailyGoal.businessDate
      );

      const items = [];

      if (goal?.salesComp > 0) {
        items.push({
          year: formatDate(goal.compDate, "yyyy"),
          sales: goal.salesComp,
          avgTrans: goal.avgTransComp,
        });
      }

      if (goal?.salesTwoYearsAgo > 0) {
        items.push({
          year: formatDate(goal.compDateTwoYearsAgo, "yyyy"),
          sales: goal.salesTwoYearsAgo,
          avgTrans: goal.avgTransTwoYearsAgo,
        });
      }

      return items.map((x) => ({
        ...x,
        customerCount: Math.round(x.sales / x.avgTrans) || 0,
      }));
    },
    currentGoalsHint() {
      const { salesGoal, avgTransGoal } = this.dailyGoal;
      const customerCount = Math.round(salesGoal / avgTransGoal) || 0;
      return this.$t("goal.setup.editDailyGoalDialog.currentGoalsHint", {
        sales: formatNumber(salesGoal, 0, "currency"),
        avgTrans: formatNumber(avgTransGoal, 2, "currency"),
        customerCount,
      });
    },
    customerCount() {
      return Math.round(this.form.salesGoal / this.form.avgTransGoal) || 0;
    },
    formattedDate() {
      return formatDate(
        this.form.businessDate,
        this.$t("goal.setup.editDailyGoalDialog.dateFormat")
      );
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.submit();
    },
    ok(formContext) {
      this.$emit("ok", { ...formContext, values: { ...this.form } });
    },
  },
};
</script>
