<template>
  <RaiSetupForm
    :title="$t('goal.setup.editGoal.title')"
    v-bind="$attrs"
    v-on="$listeners"
    @submit="updateGoals"
  >
    <template #extension>
      {{ $t("goal.setup.editGoal.extension") }}
      <EditDailyGoalDialog
        v-if="showUpdateDailyGoalDialog"
        :value="true"
        :daily-goal="dailyDialogData"
        :month-goals="selectedMonthGoals"
        @ok="updateDailyGoalFromDialog"
        @cancel="cancelDailyUpdateDialog"
      />
      <EditMonthlyGoalDialog
        v-if="showUpdateMonthlyGoalDialog"
        :value="true"
        :monthly-goal="monthlyDialogData"
        @ok="updateMonthlyGoalFromDialog"
        @cancel="cancelMonthlyUpdateDialog"
      />
      <AddMonthlyGoalDialog
        v-if="showAddMonthlyGoalDialog"
        :value="true"
        :monthly-goal="monthlyDialogData"
        @ok="addMonthlyGoalFromDialog"
        @cancel="cancelMonthlyAddDialog"
      />
    </template>

    <i18n tag="div" :path="timeFramei18nPath">
      <template #month-picker>
        <VxDatePicker
          :value="form.month"
          input-class="d-inline-block mx-2"
          :clearable="false"
          format="MMM, yyyy"
          name="month"
          type="month"
          :label="$t('goal.setup.editGoal.timeFrameMonthLabel')"
          :min="today"
          :loading="goalsLoading"
          dense
          @input="onMonthInput"
        />
      </template>
      <template #value>
        <RaiNumber :value="monthlySalesGoal" type="currency" :decimals="0" />
      </template>
      <template #trend>
        <RaiTrend
          class="d-inline"
          :value="monthlySalesGoalTrend"
          :show-suffix="false"
          :decimals="0"
        />
      </template>
      <template #action>
        <VxBtn v-if="hasMonthGoals" icon @click="editMonthlyGoal">
          <v-icon>$edit</v-icon>
        </VxBtn>
        <VxBtn v-else :disabled="goalsLoading" @click="addMonthlyGoal">
          <v-icon left>$plus</v-icon>
          {{ $t("goal.setup.editGoal.addMonthGoalButtonText") }}
        </VxBtn>
      </template>
    </i18n>

    <v-slide-x-transition>
      <div v-if="hasMonthGoals" class="goals-graph">
        <RaiGraph
          ref="graphGoals"
          :spec="salesSpec"
          :height="'300px'"
          chart-height="100%"
          elevation="0"
          manual-draw
          delayed
          :loading="areGoalsLoading"
          @draw="onSalesDraw"
        />
        <RaiGraph
          ref="graphCC"
          :spec="customerCountSpec"
          :height="'100px'"
          chart-height="100%"
          elevation="0"
          manual-draw
          delayed
          :loading="areGoalsLoading"
          @draw="onCustomerCountDraw"
        />
        <RaiGraph
          ref="graphAT"
          :spec="avgTransSpec"
          :height="'100px'"
          chart-height="100%"
          elevation="0"
          manual-draw
          delayed
          :loading="areGoalsLoading"
          @draw="onAverageTransactionDraw"
        />
        <VxAlert v-if="hasSalesHistory" type="info">
          <p>
            {{ $t("goal.setup.editGoal.comparisonDates.description") }}
          </p>
          <b>{{ $t("goal.setup.editGoal.comparisonDates.datesTitle") }}</b>
          <div v-if="lastYearMonthDataStartDate && lastYearMonthDataEndDate">
            {{ lastYearMonthDataStartDate }} - {{ lastYearMonthDataEndDate }}
          </div>
          <div
            v-if="twoYearsAgoMonthDataStartDate && twoYearsAgoMonthDataEndDate"
          >
            {{ twoYearsAgoMonthDataStartDate }} -
            {{ twoYearsAgoMonthDataEndDate }}
          </div>
        </VxAlert>
      </div>
    </v-slide-x-transition>

    <v-slide-x-transition>
      <v-row v-if="!hasMonthGoals">
        <v-col cols="12" md="6" offset-md="3">
          <div class="goals-graph-empty">
            <div class="goals-graph-empty__title">
              <v-icon class="text--secondary" x-large left>$goal</v-icon>
              <div class="text-h4 text--secondary">
                {{ $t("goal.setup.editGoal.empty.title") }}
              </div>
            </div>
            <p class="text--secondary">
              {{ $t("goal.setup.editGoal.empty.description") }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-slide-x-transition>

    <template #action="{ next, submitting }">
      <VxBtn v-if="goalsUpdated && !submitting" secondary @click="onReset">
        {{ $t("goal.setup.editGoal.resetText") }}
      </VxBtn>
      <v-skeleton-loader :loading="$attrs.loading" type="button">
        <VxBtn
          :loading="submitting"
          text
          :disabled="!goalsUpdated"
          @click="next"
        >
          {{ $t("goal.setup.editGoal.nextText") }}
        </VxBtn>
      </v-skeleton-loader>
    </template>
  </RaiSetupForm>
</template>

<script>
import { vega } from "vega-embed";
import throttle from "lodash/throttle";
import { hasSnackbarAccess } from "@/mixins/ui";
import { subscribeOneOff } from "@/utils/graphql";
import { useFormObject } from "@/mixins/useFormObject";
import { useConfirmBackDialogInject } from "@/mixins/useConfirmBackDialog";
import { mapGetters } from "vuex";

import { format, parseISO, startOfMonth, endOfMonth, isPast } from "date-fns";

import EditDailyGoalDialog from "./EditDailyGoalDialog.vue";
import EditMonthlyGoalDialog from "./EditMonthlyGoalDialog.vue";
import AddMonthlyGoalDialog from "./AddMonthlyGoalDialog.vue";
import {
  RaiGraph,
  RaiSetupForm,
  RaiNumber,
  RaiTrend,
  VxDatePicker,
  VxAlert,
  VxBtn,
  formatDate,
} from "@/core-ui";

import {
  buildCustomerCountSpec,
  buildAvgTransSpec,
  buildSalesSpec,
} from "../specs";

import {
  DAILY_REPORTS_QUERY,
  MONTHLY_GOAL_ADD,
  MONTHLY_GOAL_ADDED_SUBSCRIPTION,
} from "../graphql";
import { Role } from "@/constants";

export default {
  name: "EditGoalsSetup",
  components: {
    RaiSetupForm,
    RaiTrend,
    RaiNumber,
    VxDatePicker,
    VxAlert,
    VxBtn,
    RaiGraph,
    EditDailyGoalDialog,
    EditMonthlyGoalDialog,
    AddMonthlyGoalDialog,
  },
  mixins: [
    hasSnackbarAccess,
    useConfirmBackDialogInject(),
    useFormObject({
      prop: "goals",
      default: {
        month: new Date(),
        trend: 0,
      },
    }),
  ],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    dollarsToRoundDrag: {
      type: Number,
      default: 50,
    },
    hasSalesHistory: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    today: new Date(),
    goalsUpdated: false,
    goalsLoading: false,
    selectedMonthGoals: [],
    showUpdateDailyGoalDialog: false,
    showUpdateMonthlyGoalDialog: false,
    showAddMonthlyGoalDialog: false,
    dailyDialogData: {
      businessDate: null,
      compDate: null,
      compDateTwoYearsAgo: null,
      salesGoal: null,
      avgTransGoal: null,
    },
    monthlyDialogData: {
      businessDate: null,
      totalSales: null,
      totalSalesGrowth: null,
    },
    goalToDrag: null,
    isDragging: false,
    salesChart: null,
    customerCountChart: null,
    avgTransChart: null,
  }),
  apollo: {
    currentYearMonthGoals: {
      query: DAILY_REPORTS_QUERY,
      variables() {
        // We create start/end month variables like this to avoid problems with timezones
        return {
          storeId: this.storeId,
          startDate: format(
            startOfMonth(
              typeof this.form.month === "string"
                ? parseISO(this.form.month)
                : this.form.month
            ),
            "yyyy-MM-dd"
          ),
          endDate: format(
            endOfMonth(
              typeof this.form.month === "string"
                ? parseISO(this.form.month)
                : this.form.month
            ),
            "yyyy-MM-dd"
          ),
        };
      },
      watchLoading(loading) {
        this.goalsLoading = !!loading;
      },
      skip() {
        return !this.storeId || !this.form || !this.form.month;
      },
      update(data) {
        return data?.dailyReports;
      },
    },
    lastYearMonthGoals: {
      query: DAILY_REPORTS_QUERY,
      variables() {
        const data = this.currentYearMonthGoals;
        const startDate = data[0]?.compDate;
        const endDate = data[data.length - 1]?.compDate;
        return { storeId: this.storeId, startDate, endDate };
      },
      watchLoading(loading) {
        this.goalsLoading = !!loading;
      },
      skip() {
        return (
          !this.storeId ||
          !this.form ||
          !this.form.month ||
          !this.currentYearMonthGoals
        );
      },
      update(data) {
        return data?.dailyReports;
      },
    },
    $subscribe: {
      addMonthlyGoalUpdated: {
        query: MONTHLY_GOAL_ADDED_SUBSCRIPTION,
        variables() {
          return { storeId: this.storeId };
        },
        skip() {
          return !this.storeId;
        },
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),
    areGoalsLoading() {
      return this.goalsLoading && !this.goalsUpdated; // -> don't show loading state if goals are updated
    },
    hasMonthGoals() {
      return this.monthlySalesGoal !== 0;
    },
    computedMonthGoals() {
      // -> used to watch for changes in both arrays with single watch
      // -> this is to be used only to update `selectedMonthGoals` when change from server happens
      // -> don't reset state with this computed
      return this.createGoals(
        this.currentYearMonthGoals,
        this.lastYearMonthGoals
      );
    },
    timeFramei18nPath() {
      return `goal.setup.editGoal.timeFrame${
        this.monthlySalesGoal ? "Edit" : "Add"
      }`;
    },
    // TODO - rename parsedMonth...
    monthlySalesComp() {
      return Math.round(this.totalMonthSalesComp * 100) / 100;
    },
    monthlySalesGoal() {
      return Math.round(this.totalMonthSalesGoal * 100) / 100;
    },
    monthlySalesGoalTrend() {
      if (this.totalMonthSalesComp === 0) {
        return 0;
      }

      const trend =
        (this.totalMonthSalesGoal - this.totalMonthSalesComp) /
        this.totalMonthSalesComp;

      return Math.round(trend * 100) / 100;
    },
    // TODO - rename rawMonth...
    totalMonthSalesGoal() {
      return this.selectedMonthGoals.reduce(
        (acc, curr) => acc + curr.salesGoal,
        0
      );
    },
    totalMonthSalesComp() {
      return this.selectedMonthGoals.reduce(
        (acc, curr) => acc + curr.salesComp,
        0
      );
    },
    twoYearsAgoMonthDataStartDate() {
      if (!this.lastYearMonthGoals) return "";
      return formatDate(
        this.lastYearMonthGoals[0]?.compDate,
        this.$t("goal.setup.editGoal.comparisonDates.format")
      );
    },
    twoYearsAgoMonthDataEndDate() {
      if (!this.lastYearMonthGoals) return "";
      return formatDate(
        this.lastYearMonthGoals[this.lastYearMonthGoals.length - 1]?.compDate,
        this.$t("goal.setup.editGoal.comparisonDates.format")
      );
    },
    lastYearMonthDataStartDate() {
      if (!this.currentYearMonthGoals) return "";
      return formatDate(
        this.currentYearMonthGoals[0]?.compDate,
        this.$t("goal.setup.editGoal.comparisonDates.format")
      );
    },
    lastYearMonthDataEndDate() {
      if (!this.currentYearMonthGoals) return "";
      return formatDate(
        this.currentYearMonthGoals[this.currentYearMonthGoals.length - 1]
          ?.compDate,
        this.$t("goal.setup.editGoal.comparisonDates.format")
      );
    },
    salesSpec() {
      return buildSalesSpec({
        isDark: this.$vuetify.theme.dark,
        dailyReports: this.selectedMonthGoals,
        currentYear: new Date(this.form.month).getFullYear(),
        hasOwnerAccess: this.hasOwnerAccess,
      });
    },
    customerCountSpec() {
      return buildCustomerCountSpec({
        isDark: this.$vuetify.theme.dark,
        dailyReports: this.selectedMonthGoals,
        barWidth: 20,
      });
    },
    avgTransSpec() {
      return buildAvgTransSpec({
        isDark: this.$vuetify.theme.dark,
        dailyReports: this.selectedMonthGoals,
        barWidth: 20,
      });
    },
    updateDailySalesGoalThrottled() {
      return throttle(this.updateDailySalesGoal, 100);
    },
    hasOwnerAccess() {
      return this.userRole >= Role.OWNER;
    },
  },
  watch: {
    goalsUpdated: {
      handler(value) {
        this.setDirtyFlag(value);
      },
    },
    computedMonthGoals: {
      handler(value) {
        this.selectedMonthGoals = value.slice();
      },
    },
    selectedMonthGoals: {
      async handler(value) {
        if (value?.length > 0) {
          await this.redrawGraphs();
        }
      },
    },
  },
  methods: {
    createGoals(currentYearGoals, lastYearGoals = []) {
      if (!Array.isArray(currentYearGoals)) {
        return [];
      }

      return currentYearGoals.map((goal) => {
        const data = { ...goal };
        data.salesGoal = data.salesGoal ?? 0;
        data.avgTransGoal = data.avgTransGoal ?? 0;
        data.customerCountGoal = data.customerCountGoal ?? 0;

        data.salesComp = data.salesComp ?? 0;
        data.avgTransComp = data.avgTransComp ?? 0;
        data.customerCountComp = data.customerCountComp ?? 0;

        data.businessDateOriginal = data.businessDate; // -> we need to remember original date, as vega seems to change businessDate to number
        data.past = isPast(parseISO(`${data.businessDate} 23:59:59`)); // -> uses Date.now() internally so we have to concatenate time so that present date is not in the past

        const compareReport = lastYearGoals?.find(
          (r) => r.businessDate === data?.compDate
        );

        data.compDateTwoYearsAgo = compareReport?.compDate;
        data.salesTwoYearsAgo = compareReport?.salesComp ?? 0;
        data.avgTransTwoYearsAgo = compareReport?.avgTransComp ?? 0;
        data.customerCountTwoYearsAgo = compareReport?.customerCountComp ?? 0;
        return data;
      });
    },
    async onMonthInput(value) {
      if (!this.goalsUpdated) {
        this.form.month = value;
        return;
      }

      const options = this.$t("goal.setup.editGoal.confirmNextMonthDialog");
      const goToNextMonth = await this.$dialog.confirm(options);

      if (!goToNextMonth) {
        return;
      }

      this.form.month = value;
      this.goalsUpdated = false;
    },
    onAverageTransactionDraw(chart) {
      this.avgTransChart = chart;
    },
    onCustomerCountDraw(chart) {
      this.customerCountChart = chart;
    },
    onSalesDraw(chart) {
      const canEditDailyGoal = (goal) =>
        goal?.past === false || this.hasOwnerAccess;
      chart.view.addEventListener("mousemove", (data) => {
        if (!this.goalToDrag) {
          return;
        }
        if (canEditDailyGoal(this.goalToDrag)) {
          this.isDragging = true;

          const scaleY = this.salesChart.view.scale("y").invert(data.vega.y());
          let newSalesGoal =
            Math.round(scaleY / this.dollarsToRoundDrag) *
            this.dollarsToRoundDrag;

          if (newSalesGoal < 0) {
            newSalesGoal = 0;
          }

          const { businessDateOriginal, avgTransGoal } = this.goalToDrag;
          this.updateDailySalesGoalThrottled({
            businessDate: businessDateOriginal,
            salesGoal: newSalesGoal,
            avgTransGoal,
          });
        }
      });

      chart.view.addEventListener("mousedown", (_, context) => {
        if (canEditDailyGoal(context?.datum) && this.goalToDrag === null) {
          this.goalToDrag = context.datum;
        }
      });

      chart.view.addEventListener("mouseup", (_, context) => {
        if (
          context?.datum &&
          canEditDailyGoal(context?.datum) &&
          !this.isDragging
        ) {
          const { businessDateOriginal: businessDate, ...values } =
            context.datum;

          this.dailyDialogData = { ...values, businessDate };
          this.showUpdateDailyGoalDialog = true;
        }

        this.goalToDrag = null;
        this.isDragging = false;
      });

      this.salesChart = chart;
    },
    updateDailySalesGoal({ businessDate, salesGoal, avgTransGoal }) {
      const tuple = (field) => field.businessDateOriginal === businessDate;
      const changeSet = vega
        .changeset()
        .modify(tuple, "salesGoal", salesGoal)
        .modify(tuple, "avgTransGoal", avgTransGoal)
        .modify(
          tuple,
          "customerCountGoal",
          Math.round(salesGoal / avgTransGoal)
        );

      this.salesChart?.view.change("reportsSales", changeSet).run();
      this.customerCountChart?.view.change("reportsSales", changeSet).run();
      this.avgTransChart?.view.change("reportsSales", changeSet).run();
      this.goalsUpdated = true;
    },
    updateDailyGoalFromDialog({ values }) {
      const { businessDate, salesGoal, avgTransGoal } = values;
      this.updateDailySalesGoal({ businessDate, salesGoal, avgTransGoal });
      this.hideUpdateDailyGoalDialog();
    },
    cancelDailyUpdateDialog() {
      this.hideUpdateDailyGoalDialog();
    },
    hideUpdateDailyGoalDialog() {
      this.showUpdateDailyGoalDialog = false;
      this.dailyDialogData = {
        businessDate: null,
        compDate: null,
        compDateTwoYearsAgo: null,
        salesGoal: null,
        avgTransGoal: null,
      };
    },
    addMonthlyGoal() {
      this.monthlyDialogData = {
        businessDate: this.form.month,
        totalSales: this.monthlySalesGoal,
        totalSalesComp: this.monthlySalesComp,
        totalSalesGrowth: this.monthlySalesGoalTrend * 100,
      };
      this.showAddMonthlyGoalDialog = true;
    },
    cancelMonthlyAddDialog() {
      this.hideAddMonthlyGoalDialog();
    },
    async addMonthlyGoalFromDialog(context) {
      const { values, setErrors } = context;
      this.subscribeToMonthlyGoalsUpdated(context);

      const { data } = await this.$apollo.mutate({
        mutation: MONTHLY_GOAL_ADD,
        variables: {
          storeId: this.storeId,
          input: {
            businessDate: this.form.month,
            salesGoal: values.totalSales,
          },
        },
      });

      const errors = data?.monthlyGoalAdd?.errors;

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }
    },
    subscribeToMonthlyGoalsUpdated(context) {
      context.setStatus(
        this.$t("goal.setup.addMonthlyGoalDialog.addingGoalsStatus")
      );

      subscribeOneOff(this, {
        name: "addMonthlyGoalUpdated",
        resolve: async ({ success, errors }) => {
          if (!success) {
            context.setErrors(errors);
            return;
          }

          await this.$apollo.queries.currentYearMonthGoals.refetch();

          this.hideAddMonthlyGoalDialog();
          context.resolve();

          this.showSnackbar({
            text: this.$t("goal.setup.editGoal.addSuccessText", {
              monthYear: formatDate(this.form.month, "MMM, yyyy"),
            }),
            color: "success",
          });
        },
      });
    },
    hideAddMonthlyGoalDialog() {
      this.showAddMonthlyGoalDialog = false;
      this.monthlyDialogData = {
        businessDate: null,
        totalSales: null,
        totalSalesGrowth: null,
      };
    },
    editMonthlyGoal() {
      this.monthlyDialogData = {
        businessDate: this.form.month,
        totalSales: this.monthlySalesGoal,
        totalSalesComp: this.monthlySalesComp,
        totalSalesGrowth: this.monthlySalesGoalTrend * 100,
      };
      this.showUpdateMonthlyGoalDialog = true;
    },
    updateMonthlyGoalFromDialog({ values }) {
      const changeScale =
        (values.totalSales - this.monthlySalesGoal) / this.monthlySalesGoal;
      this.updateMonthlySalesGoal(changeScale);
      this.hideUpdateMonthlyGoalDialog();
    },
    updateMonthlySalesGoal(changeScale) {
      const changeSet = vega
        .changeset()
        .modify(
          () => true,
          "salesGoal",
          (field) => Math.round(field.salesGoal * (1 + changeScale) * 100) / 100
        )
        .modify(
          () => true,
          "customerCountGoal",
          (field) => Math.round(field.salesGoal / field.avgTransGoal)
        );

      this.salesChart.view.change("reportsSales", changeSet).run();

      if (this.customerCountChart) {
        // Run a non changing update of dataset - needed to refresh view
        const staticChangeSet = vega
          .changeset()
          .modify(
            () => true,
            "salesGoal",
            (field) => field.salesGoal
          )
          .modify(
            () => true,
            "customerCountGoal",
            (field) => field.customerCountGoal
          );

        this.customerCountChart.view
          .change("reportsSales", staticChangeSet)
          .run();
      }

      this.goalsUpdated = true;
    },
    cancelMonthlyUpdateDialog() {
      this.hideUpdateMonthlyGoalDialog();
    },
    hideUpdateMonthlyGoalDialog() {
      this.showUpdateMonthlyGoalDialog = false;
      this.monthlyDialogData = {
        businessDate: null,
        totalSales: null,
        totalSalesGrowth: null,
      };
    },
    async onReset() {
      const goals = this.createGoals(
        this.currentYearMonthGoals,
        this.lastYearMonthGoals
      );

      this.salesChart.view.data("reportsSales", goals);
      this.avgTransChart?.view.data("reportsSales", goals);
      this.customerCountChart?.view.data("reportsSales", goals);
      this.selectedMonthGoals = goals;
      this.goalsUpdated = false;
    },
    async updateGoals(context) {
      const resolve = async () => {
        // TODO: - SEE IF we can avoid this
        await this.$apollo.queries.currentYearMonthGoals.refetch();
        this.goalsUpdated = false;
        context.resolve();
      };

      this.$emit("update:goals", {
        ...context,
        resolve,
        values: {
          monthYear: this.form.month,
          goalsUpdate: this.selectedMonthGoals
            .filter((x, idx) => {
              const original = this.currentYearMonthGoals[idx];
              return (
                x.salesGoal !== original.salesGoal ||
                x.avgTransGoal !== original.avgTransGoal
              );
            })
            .map((x) => ({
              businessDate: x.businessDateOriginal,
              salesGoal: x.salesGoal,
              avgTransGoal: x.avgTransGoal,
            })),
        },
      });
    },
    redrawGraphs(options = {}) {
      const { timeout = 100 } = options;
      return new Promise((resolve) => {
        setTimeout(async () => {
          await this.$refs.graphGoals?.draw();
          await this.$refs.graphCC?.draw();
          await this.$refs.graphAT?.draw();
          resolve();
        }, timeout);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rai-graph {
  margin-bottom: 1rem !important;
}

::v-deep .rai-graph__wrapper {
  padding: 0px !important;
}

.goals-graph-empty {
  display: grid;
  place-content: center;
  height: 300px;

  .goals-graph-empty__title {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    gap: 1rem;
  }
}
</style>
