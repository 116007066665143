/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */
export const buildSalesSpec = (options) => {
  const { dailyReports, isDark, currentYear, hasOwnerAccess } = options;

  if (!dailyReports || dailyReports.length <= 0) {
    return {};
  }

  const hasSales = dailyReports.some(
    (r) => r.salesGoal !== null && r.salesGoal !== undefined
  );

  const hasSalesFromLastYear = dailyReports.some((x) => x.salesComp > 0);
  const hasSalesFromTwoYearsAgo = dailyReports.some(
    (x) => x.salesTwoYearsAgo > 0
  );

  const scale = {
    domain: [currentYear.toString()],
    range: ["#00b7ff"],
  };

  const tooltip = [
    { field: "title" },
    {
      field: "goalTooltipContent",
      title: "Goal " + currentYear.toString(),
    },
  ];

  if (hasSalesFromLastYear) {
    scale.domain.push((currentYear - 1).toString());
    scale.range.push(isDark ? "#7e7e7e" : "#dcdcdc");
    tooltip.push({
      field: "tooltipContentLastYear",
      title: "Actual " + (currentYear - 1).toString(),
    });
  }

  if (hasSalesFromTwoYearsAgo) {
    scale.domain.push((currentYear - 2).toString());
    scale.range.push(isDark ? "#9e9e9e" : "#acacac");
    tooltip.push({
      field: "tooltipContentTwoYearsAgo",
      title: "Actual " + (currentYear - 2).toString(),
    });
  }

  const salesLayers = hasSales
    ? [
        {
          mark: {
            type: "area",
            interpolate: "natural",
            color: isDark ? "#7e7e7e" : "#dcdcdc",
          },
          encoding: {
            y: {
              field: "salesComp",
              type: "quantitative",
            },
            color: {
              datum: (currentYear - 1).toString(),
            },
          },
        },
        {
          mark: {
            type: "line",
            interpolate: "natural",
            color: isDark ? "#9e9e9e" : "#acacac",
          },
          encoding: {
            y: {
              field: "salesTwoYearsAgo",
              type: "quantitative",
            },
            color: {
              datum: (currentYear - 2).toString(),
            },
          },
        },
        {
          mark: {
            type: "line",
            interpolate: "natural",
            color: "#00b7ff",
          },
          encoding: {
            y: {
              field: "salesGoal",
              type: "quantitative",
            },
            color: {
              datum: currentYear.toString(),
            },
          },
        },
        {
          // TODO: investigate why does this cause an Vega embed console error
          // params: [
          //   {
          //     name: "highlight_point",
          //     select: {
          //       type: "point",
          //       encodings: ["x"],
          //       on: "mouseover",
          //       nearest: true,
          //     },
          //   },
          // ],
          transform: [
            {
              calculate: "utcFormat(datum.businessDate,'%A %b %e')",
              as: "title",
            },
            {
              calculate:
                "'$' + pad(''+datum.salesGoal, 30) + ' | Avg. Trans: ' + '$'+ datum.avgTransGoal + ' | Cust. Count: ' + datum.customerCountGoal",
              as: "goalTooltipContent",
            },
            {
              calculate:
                "'$' + pad(''+datum.salesComp, 30) + ' | Avg. Trans: ' + '$'+ datum.avgTransComp + ' | Cust. Count: ' + datum.customerCountComp",
              as: "tooltipContentLastYear",
            },
            {
              calculate:
                "'$' + pad(''+datum.salesTwoYearsAgo, 30) + ' | Avg. Trans: ' + '$'+ datum.avgTransTwoYearsAgo + ' | Cust. Count: ' + datum.customerCountTwoYearsAgo",
              as: "tooltipContentTwoYearsAgo",
            },
          ],

          mark: {
            type: "circle",
            interpolate: "natural",
            color: "#00b7ff",
            size: 90,
          },
          encoding: {
            y: {
              field: "salesGoal",
              type: "quantitative",
            },
            opacity: hasOwnerAccess
              ? {
                  value: 1,
                }
              : {
                  value: 0,
                  condition: [
                    {
                      test: "datum['past'] === false",
                      value: 1,
                    },
                  ],
                },
            tooltip,
          },
        },
      ]
    : [];

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: {
      name: "reportsSales",
      values: dailyReports || [],
    },
    transform: [
      {
        calculate: "datum.salesCompPM / datum.salesComp",
        as: "growth",
      },
    ],
    encoding: {
      x: {
        field: "businessDate",
        type: "temporal",
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: true,
          domain: false,
        },
      },
      y: {
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      color: {
        scale,
      },
    },
    layer: salesLayers,
    config: {
      legend: {
        orient: "top",
        layout: { top: { anchor: "left" } },
        symbolType: "square",
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
