<template>
  <VxDialog
    :title="$t('goal.setup.addMonthlyGoalDialog.title')"
    :h-size="'xsmall'"
    no-back
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="text-h6 primary--text text-uppercase mb-6">
      {{ formattedDate }}
    </div>
    <VxForm ref="form" class="mb-4" @submit="ok">
      <VxTextField
        v-model.number="form.totalSales"
        type="number"
        rules="required"
        :label="$t('goal.setup.addMonthlyGoalDialog.salesField.label')"
        :placeholder="
          $t('goal.setup.addMonthlyGoalDialog.salesField.placeholder')
        "
        name="totalSales"
        prefix="$"
      />
    </VxForm>
    <i18n :path="`goal.setup.addMonthlyGoalDialog.description`" tag="p" />
    <v-fade-transition>
      <span v-show="!!status" class="pl-2 text-caption text-truncate">
        {{ status }}
      </span>
    </v-fade-transition>
    <template #actions>
      <VxBtn secondary @click="$emit('cancel')">
        {{ $t("goal.setup.addMonthlyGoalDialog.cancelBtnText") }}
      </VxBtn>
      <VxBtn :loading="submitting" text @click="onSubmit">
        {{ $t("goal.setup.addMonthlyGoalDialog.okBtnText") }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import { VxDialog, VxForm, VxTextField, VxBtn, formatDate } from "@/core-ui";

export default {
  name: "AddMonthlyGoalDialog",
  components: {
    VxDialog,
    VxForm,
    VxTextField,
    VxBtn,
  },
  mixins: [
    useFormObject({
      prop: "monthlyGoal",
      default: {
        date: null,
        totalSales: null,
      },
    }),
  ],
  data: () => ({
    status: null,
    submitting: false,
  }),
  computed: {
    formattedDate() {
      return formatDate(
        this.form.date,
        this.$t("goal.setup.addMonthlyGoalDialog.dateFormat")
      );
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.submit();
    },
    setStatus(status) {
      this.status = status;
    },
    ok(formContext) {
      const resolve = () => {
        this.status = null;
        this.submitting = false;
        formContext.resolve();
      };

      const setErrors = (errors) => {
        this.status = null;
        this.submitting = false;
        formContext.setErrors(errors);
      };

      this.submitting = true;
      this.$emit("ok", {
        ...formContext,
        values: { ...this.form },
        setStatus: this.setStatus,
        resolve,
        setErrors,
      });
    },
  },
};
</script>
