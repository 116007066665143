<template>
  <RaiSetups v-model="activeSetup">
    <MagicGoalsSetup
      :magic-goal="magicGoal"
      :type="completedSetups[0]"
      :has-sales-history="hasSalesHistory"
      :loading="loading"
      @update:magic-goal="onUpdateMagicGoals"
      @click:monthly-goal="onMonthlyGoalClick"
      @next="onNext"
    />
    <EditGoalsSetup
      ref="editGoal"
      :store-id="storeId"
      :type="completedSetups[1]"
      :has-sales-history="hasSalesHistory"
      :loading="loading"
      @update:goals="onUpdateGoals"
    />
  </RaiSetups>
</template>

<script>
import { hasSnackbarAccess } from "@/mixins/ui";

import { startOfYear, endOfYear } from "date-fns";
import { subscribeOneOff } from "@/utils/graphql";
import { MagicGoalsSetup, EditGoalsSetup } from "./components";
import { RaiSetups, SetupType, formatDate } from "@/core-ui";
import { useConfirmBackDialog } from "@/mixins/useConfirmBackDialog";
import {
  MAGIC_GOAL_AUTOMATION_QUERY,
  MAGIC_GOAL_AUTOMATION_UPDATE,
  MAGIC_GOAL_DAILY_GOALS_UPDATED_SUBSCRIPTION,
  HAS_SALES_HISTORY,
  BATCH_UPDATE_DAILY_GOALS,
  BATCH_UPDATE_DAILY_GOALS_UPDATED_SUBSCRIPTION,
} from "./graphql";

export default {
  name: "GoalSetupView",
  components: {
    RaiSetups,
    MagicGoalsSetup,
    EditGoalsSetup,
  },
  mixins: [hasSnackbarAccess, useConfirmBackDialog()],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    activeSetup: 0,
    completedSetups: new Array(2),
    hasSalesHistory: false,
  }),
  apollo: {
    hasSalesHistory: {
      query: HAS_SALES_HISTORY,
      variables() {
        return {
          storeId: this.storeId,
          startDate: startOfYear(new Date()),
          endDate: endOfYear(new Date()),
        };
      },
      watchLoading(loading) {
        this.setLoading(loading);
      },
      skip() {
        return !this.storeId;
      },
      update(data) {
        return data?.hasSalesHistory;
      },
    },
    magicGoal: {
      query: MAGIC_GOAL_AUTOMATION_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
      update(response) {
        return response.magicGoalAutomation;
      },
    },
    $subscribe: {
      magicGoalsDailyGoalsUpdated: {
        query: MAGIC_GOAL_DAILY_GOALS_UPDATED_SUBSCRIPTION,
        variables() {
          return {
            storeId: this.storeId,
          };
        },
        skip() {
          return !this.storeId;
        },
      },
      batchUpdateDailyGoalsUpdated: {
        query: BATCH_UPDATE_DAILY_GOALS_UPDATED_SUBSCRIPTION,
        variables() {
          return {
            storeId: this.storeId,
          };
        },
        skip() {
          return !this.storeId;
        },
      },
    },
  },
  watch: {
    async activeSetup(step) {
      // We need this, as if the first step was 0, the graph will not be drawn
      if (step === 1) {
        await this.$refs.editGoal.redrawGraphs();
      }
    },
  },
  methods: {
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    onNext() {
      this.completedSetups[this.activeSetup] = SetupType.COMPLETE;
      this.activeSetup++;
    },
    onMonthlyGoalClick() {
      this.activeSetup = 1;
    },
    async onUpdateMagicGoals(context) {
      const { values, setErrors } = context;
      this.subscribeToGoalsUpdated(context);

      const { data } = await this.$apollo.mutate({
        mutation: MAGIC_GOAL_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: values,
        },
      });

      const errors = data?.magicGoalAutomationUpdate?.errors;

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

      // TODO - update the cache properly
      this.magicGoal = {
        ...this.magicGoal,
        ...data.magicGoalAutomationUpdate.automation,
      };
    },
    subscribeToGoalsUpdated(context) {
      context.setStatus(
        this.$t("goal.setup.magicGoal.updateDialog.updatingGoalsStatus")
      );

      subscribeOneOff(this, {
        name: "magicGoalsDailyGoalsUpdated",
        resolve: async (result) => {
          if (!result.success) {
            context.setErrors(result.errors);
            return;
          }

          this.showSnackbar({
            text: this.$t("goal.setup.magicGoal.updateSuccessText"),
            color: "success",
          });

          context.resolve();
          await this.$refs.editGoal.redrawGraphs();
        },
      });
    },
    async onUpdateGoals(context) {
      const { values, setErrors } = context;
      this.subscribeToBatchGoalsUpdated(context);

      const { goalsUpdate } = values;
      const { data } = await this.$apollo.mutate({
        mutation: BATCH_UPDATE_DAILY_GOALS,
        variables: {
          storeId: this.storeId,
          input: { goalsUpdate },
        },
      });

      const errors = data?.batchUpdateDailyGoals?.errors;

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }
    },
    subscribeToBatchGoalsUpdated(context) {
      subscribeOneOff(this, {
        name: "batchUpdateDailyGoalsUpdated",
        resolve: async (result) => {
          if (!result.success) {
            context.setErrors(result.errors);
            return;
          }

          await context.resolve();

          this.showSnackbar({
            text: this.$t("goal.setup.editGoal.updateSuccessText", {
              monthYear: formatDate(context.values.monthYear, "MMM, yyyy"),
            }),
            color: "success",
          });
        },
      });
    },
  },
};
</script>
