// ### MAGIC GOAL ###
export * from "./MagicGoalAutomationQuery.gql";
export * from "./MagicGoalAutomationUpdate.gql";
export * from "./MagicGoalDailyGoalsUpdatedSubscription.gql";

// ### Daily reports ###
export * from "./DailyReportsQuery.gql";
export * from "./DailyReportsUpdate.gql";
export * from "./DailyReportsUpdatedSubscription.gql";
export * from "./HasSalesHistoryQuery.gql";
export * from "./MonthlyGoalAdd.gql";
export * from "./MonthlyGoalAddedSubscription.gql";
