<template>
  <VxDialog
    :title="$t('goal.setup.editMonthlyGoalDialog.title')"
    no-back
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="text-h6 primary--text text-uppercase mb-6">
      {{ formattedDate }}
    </div>
    <VxForm ref="form" v-slot="{ dirty }" class="mb-4" @submit="ok">
      <v-row>
        <v-col>
          <VxTextField
            v-model.number="form.totalSales"
            :hint="totalSalesHint"
            :persistent-hint="dirty"
            type="number"
            rules="required"
            :label="
              $t('goal.setup.editMonthlyGoalDialog.totalSalesField.label')
            "
            :placeholder="
              $t('goal.setup.editMonthlyGoalDialog.totalSalesField.placeholder')
            "
            name="totalSales"
            prefix="$"
            @input="onTotalSalesInput"
          />
        </v-col>
        <v-col>
          <VxTextField
            v-model.number="form.totalSalesGrowth"
            :hint="totalSalesGrowthHint"
            :disabled="!hasSalesHistory"
            :persistent-hint="hasSalesHistory && dirty"
            type="number"
            rules="required"
            :label="
              $t('goal.setup.editMonthlyGoalDialog.totalSalesGrowthField.label')
            "
            :placeholder="
              $t(
                'goal.setup.editMonthlyGoalDialog.totalSalesGrowthField.placeholder'
              )
            "
            name="totalSalesGrowth"
            suffix="%"
            @input="onTotalSalesGrowthInput"
          />
        </v-col>
      </v-row>
    </VxForm>
    <p
      v-for="(desc, key) in $t('goal.setup.editMonthlyGoalDialog.description')"
      :key="key"
    >
      {{ desc }}
    </p>
    <template #actions>
      <VxBtn secondary @click="$emit('cancel')">
        {{ $t("goal.setup.editMonthlyGoalDialog.cancelBtnText") }}
      </VxBtn>
      <VxBtn text @click="onSubmit">
        {{ $t("goal.setup.editMonthlyGoalDialog.okBtnText") }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import {
  VxDialog,
  VxForm,
  VxTextField,
  VxBtn,
  formatDate,
  formatNumber,
} from "@/core-ui";

export default {
  name: "EditMonthlyGoalDialog",
  components: {
    VxDialog,
    VxForm,
    VxTextField,
    VxBtn,
  },
  mixins: [
    useFormObject({
      prop: "monthlyGoal",
      default: {
        businessDate: null,
        totalSales: null,
        totalSalesComp: null,
        totalSalesGrowth: null,
      },
      handler(data) {
        const { totalSales, totalSalesGrowth } = data;
        data.totalSales = Math.round(totalSales);
        data.totalSalesGrowth = Math.round(totalSalesGrowth);
      },
    }),
  ],
  computed: {
    formattedDate() {
      return formatDate(
        this.form.businessDate,
        this.$t("goal.setup.editMonthlyGoalDialog.dateFormat")
      );
    },
    hasSalesHistory() {
      return this.monthlyGoal.totalSalesComp > 0;
    },
    totalSalesHint() {
      return this.$t("goal.setup.editMonthlyGoalDialog.currentGoalHint", [
        formatNumber(this.monthlyGoal.totalSales, 0, "currency"),
      ]);
    },
    totalSalesGrowthHint() {
      return this.$t("goal.setup.editMonthlyGoalDialog.currentGoalHint", [
        formatNumber(this.monthlyGoal.totalSalesGrowth / 100, 0, "percent"),
      ]);
    },
  },
  methods: {
    onTotalSalesInput(value) {
      const { totalSalesComp } = this.monthlyGoal;
      const salesGrowth = ((value - totalSalesComp) / totalSalesComp) * 100;
      this.form.totalSalesGrowth = Math.round(salesGrowth);
    },
    onTotalSalesGrowthInput(value) {
      const { totalSalesComp } = this.monthlyGoal;
      const sales = totalSalesComp * (1 + value / 100);
      this.form.totalSales = Math.round(sales);
    },
    onSubmit() {
      this.$refs.form.submit();
    },
    ok(context) {
      this.$emit("ok", { ...context, values: { ...this.form } });
    },
  },
};
</script>
