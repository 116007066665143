<template>
  <ValidationObserver v-slot="{ dirty }" slim>
    <RaiSetupForm
      :title="$t('goal.setup.magicGoal.title')"
      v-bind="$attrs"
      v-on="$listeners"
      @submit="onSubmit"
    >
      <template #extension>
        <p
          v-for="(text, key) in $t('goal.setup.magicGoal.extension')"
          :key="key"
        >
          {{ text }}
        </p>
        <ValidationObserver ref="updateObsv" v-slot="{ handleSubmit }" slim>
          <VxDialog
            v-model="showUpdateDialog"
            :title="$t('goal.setup.magicGoal.updateDialog.title')"
            :v-size="'small'"
            :h-size="'xsmall'"
            persistent
            no-back
          >
            <div class="pt-1">
              <VxDatePicker
                v-model="form.updateFromDate"
                outlined
                persistent-placeholder
                name="updateFromDate"
                :placeholder="$t('goal.setup.magicGoal.startDate')"
                :label="$t('goal.setup.magicGoal.startDate')"
                :min="today"
                rules="required"
              />
            </div>
            <VxAlert :type="$t('goal.setup.magicGoal.updateDialog.alert.type')">
              <strong>
                {{ $t("goal.setup.magicGoal.updateDialog.alert.prefix") }}
              </strong>
              {{ $t("goal.setup.magicGoal.updateDialog.alert.message") }}
            </VxAlert>
            <v-fade-transition>
              <span
                v-show="!!updateStatus"
                class="pl-2 text-caption text-truncate"
              >
                {{ updateStatus }}
              </span>
            </v-fade-transition>
            <template #actions>
              <VxBtn secondary @click="onUpdateDialogCancel">
                {{ $t("goal.setup.magicGoal.updateDialog.cancel") }}
              </VxBtn>
              <VxBtn
                text
                :loading="updateLoading"
                @click="handleSubmit(updateMagicGoal)"
              >
                {{ $t("goal.setup.magicGoal.updateDialog.ok") }}
              </VxBtn>
            </template>
          </VxDialog>
        </ValidationObserver>
      </template>
      <div v-if="hasSalesHistory" class="magic-goals">
        <VxTable class="magic-goals__table mb-4">
          <template #body>
            <tbody>
              <VxTableRow>
                <VxTableHeaderCell type="text">
                  {{ $t("goal.setup.magicGoal.salesGrowth") }}
                </VxTableHeaderCell>
                <VxTableDataCell type="text" class="pa-2">
                  <VxTextField
                    v-model.number="form.targetGrowth"
                    hide-details
                    type="number"
                    name="targetGrowth"
                    :label="$t('goal.setup.magicGoal.salesGrowth')"
                    :placeholder="$t('goal.setup.magicGoal.salesGrowth')"
                    rules="required|integer_goal"
                    suffix="%"
                  />
                </VxTableDataCell>
              </VxTableRow>
              <VxTableRow>
                <VxTableHeaderCell type="text">
                  {{ $t("goal.setup.magicGoal.averageTransaction") }}
                </VxTableHeaderCell>
                <VxTableDataCell type="text" class="pa-2">
                  <VxTextField
                    v-model.number="form.minAvgGoal"
                    hide-details
                    type="number"
                    name="minAvgGoal"
                    :placeholder="$t('goal.setup.magicGoal.averageTransaction')"
                    :label="$t('goal.setup.magicGoal.averageTransaction')"
                    rules="required|min_value:1|integer"
                    :min="1"
                    prefix="$"
                  />
                </VxTableDataCell>
              </VxTableRow>
              <VxTableRow>
                <VxTableHeaderCell type="text">
                  {{ $t("goal.setup.magicGoal.tradePct") }}
                </VxTableHeaderCell>
                <VxTableDataCell type="text" class="pa-2">
                  <VxTextField
                    v-model.number="form.tradeGoal"
                    hide-details
                    type="number"
                    name="tradeGoal"
                    :placeholder="$t('goal.setup.magicGoal.tradePct')"
                    :label="$t('goal.setup.magicGoal.tradePct')"
                    rules="required|min_value:1|integer"
                    :min="1"
                    suffix="%"
                  />
                </VxTableDataCell>
              </VxTableRow>
              <VxTableRow>
                <VxTableHeaderCell type="text">
                  {{ $t("goal.setup.magicGoal.minSales") }}
                </VxTableHeaderCell>
                <VxTableDataCell type="text" class="pa-2">
                  <VxTextField
                    v-model.number="form.minSalesGoal"
                    hide-details
                    type="number"
                    name="minSalesGoal"
                    :placeholder="$t('goal.setup.magicGoal.minSales')"
                    :label="$t('goal.setup.magicGoal.minSales')"
                    rules="integer"
                  />
                </VxTableDataCell>
              </VxTableRow>
            </tbody>
          </template>
        </VxTable>
      </div>
      <VxAlert v-else :dense="false" text color="info">
        <div class="text-h4 mb-5">
          {{ $t("goal.setup.magicGoal.noSales.title") }}
        </div>
        <i18n tag="p" path="goal.setup.magicGoal.noSales.description[0]">
          <template #link>
            <RaiLink
              :href="$t('goal.setup.magicGoal.noSales.learnHowLink.href')"
            >
              {{ $t("goal.setup.magicGoal.noSales.learnHowLink.text") }}
            </RaiLink>
          </template>
        </i18n>
        <i18n tag="p" path="goal.setup.magicGoal.noSales.description[1]">
          <template #link>
            <RaiLink @click="$emit('click:monthly-goal')">
              {{ $t("goal.setup.magicGoal.noSales.totalMonthlyGoalLink.text") }}
            </RaiLink>
          </template>
        </i18n>
      </VxAlert>
      <template #action="{ next }">
        <VxBtn text :disabled="!dirty" @click="next">
          {{ $t("goal.setup.magicGoal.nextText") }}
        </VxBtn>
      </template>
    </RaiSetupForm>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { useFormObject } from "@/mixins/useFormObject";
import {
  RaiLink,
  RaiSetupForm,
  VxTable,
  VxTableRow,
  VxTableDataCell,
  VxDialog,
  VxTableHeaderCell,
  VxTextField,
  VxDatePicker,
  VxAlert,
  VxBtn,
} from "@/core-ui";

export default {
  name: "MagicGoalsSetup",
  components: {
    RaiLink,
    RaiSetupForm,
    VxTextField,
    VxDatePicker,
    VxDialog,
    VxTable,
    VxTableRow,
    VxTableDataCell,
    VxTableHeaderCell,
    ValidationObserver,
    VxAlert,
    VxBtn,
  },
  mixins: [
    useFormObject({
      prop: "magicGoal",
      default: {
        targetGrowth: null,
        minAvgGoal: null,
        tradeGoal: null,
        minSalesGoal: null,
        updateFromDate: null,
      },
    }),
  ],
  props: {
    hasSalesHistory: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    updateLoading: false,
    updateStatus: null,
    formContext: null,
    showUpdateDialog: false,
    today: new Date(),
  }),
  created() {
    this.form.updateFromDate = this.today;
  },
  methods: {
    updateMagicGoal() {
      const { values, resolve, setErrors } = this.formContext;
      values.updateFromDate = this.form.updateFromDate;

      this.updateLoading = true;
      this.$emit("update:magic-goal", {
        values,
        setErrors: (errors) => {
          this.hideUpdateDialog();
          setErrors(errors);
        },
        setStatus: (status) => {
          this.updateStatus = status;
        },
        resolve: () => {
          this.hideUpdateDialog();
          resolve();
        },
      });
    },
    onSubmit(formContext) {
      this.showUpdateDialog = true;
      this.formContext = formContext;
    },
    onUpdateDialogCancel() {
      this.hideUpdateDialog();
      this.form = { ...this.magicGoal };
    },
    hideUpdateDialog() {
      this.updateStatus = null;
      this.updateLoading = false;
      this.showUpdateDialog = false;
      this.$refs.updateObsv.reset();
      this.formContext = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.magic-goals__table tbody tr > th {
  text-transform: uppercase;
}
</style>
