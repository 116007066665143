/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */
export const buildCustomerCountSpec = (options) => {
  const { dailyReports, barWidth, isDark } = options;
  if (!dailyReports || dailyReports.length <= 0) {
    return {};
  }

  const hasCustomerCount = dailyReports.some(
    (r) => r.customerCountGoal !== null && r.customerCountGoal !== undefined
  );

  const customerCountLayers = !hasCustomerCount
    ? []
    : [
        {
          params: [
            {
              name: "highlight_bar",
              select: { type: "point", on: "mouseover" },
            },
          ],
          mark: {
            type: "bar",
            color: "#00b7ff",
            width: barWidth,
          },
          transform: [
            {
              calculate: "datum.businessDate",
              as: "title",
            },
          ],
          encoding: {
            y: {
              field: "customerCountGoal",
              type: "quantitative",
            },
            color: {
              datum: "Customer count",
              condition: [
                {
                  param: "highlight_bar",
                  empty: false,
                  value: "#333333",
                },
              ],
            },
            tooltip: [
              { field: "title", type: "temporal" },
              {
                field: "customerCountGoal",
                type: "quantitative",
                title: "Count",
              },
            ],
          },
        },
      ];

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: { name: "reportsSales", values: dailyReports || [] },
    encoding: {
      x: {
        field: "businessDate",
        type: "temporal",
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      y: {
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      color: {
        scale: {
          domain: ["Customer count"],
          range: ["#00b7ff"],
        },
      },
    },
    layer: customerCountLayers,
    config: {
      legend: {
        orient: "top",
        layout: { top: { anchor: "left" } },
        symbolType: "square",
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
